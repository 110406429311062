* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    /* font-family: sans-serif; */
    margin: 0;
    font-family: -apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        Roboto,
        "Helvetica Neue",
        Arial,
        "Noto Sans",
        sans-serif,
        "Apple Color Emoji",
        "Segoe UI Emoji",
        "Segoe UI Symbol",
        "Noto Color Emoji";
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212837;
    text-align: left;
    /*background: #c9d2e3;*/
    background: #e9f0ff;
    min-height: 100vh;
}

button {
    cursor: pointer;
    border: 0;
}

a {
    text-decoration: none;
}

.bg-info {
    background-color: #17a2b8 !important;
    color: #fff !important;
}

.text-primary {
    color: #003768 !important;
}

.text-primary .active {
    background-color: #003768 !important;
}

.btn-primary {
    background-color: #003768 !important;
    --bs-btn-border-color: #003768 !important;
}

.dropdown-item:hover {
    background-color: #003768 !important;
    color: #fff !important;
}

.form-control-sm {
    font-size: .75rem !important;
}

.form-label {
    font-size: .825rem !important;
}

.form-select-sm {
    font-size: .75rem !important;
}

.table-sm td,
.table-sm th {
    padding: 0.1rem;
}

.table thead th {
    vertical-align: bottom !important;
    border-bottom: 2px solid #dae0ec !important;
}

th {
    font-weight: bold !important;
}

.table td,
.table th {
    padding: 0.1rem;
    vertical-align: top;
    border-top: 1px solid #dae0ec;
}

.stack-0 {
    z-index: 1039;
    display: block;
}

.stack-b-0 {
    z-index: 1040;
    display: block;
}

.stack-1 {
    z-index: 1049;
    display: block;
}

.stack-b-1 {
    z-index: 1050;
    display: block;
}

.stack-2 {
    z-index: 1059;
    display: block;
}

.stack-b-2 {
    z-index: 1060;
    display: block;
}